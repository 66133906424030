<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="elevation-2" :loading="loading" :disabled="loading">
            <v-toolbar color="white" flat>
              <v-toolbar-title>{{ $t('login.title') }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-alert
                :value="error !== null"
                border="left"
                colored-border
                type="error"
                elevation="2"
              >
                <strong v-if="code">{{ code }}</strong>
                {{ message }}
              </v-alert>

              <v-form ref="form">
                <v-text-field
                  :label="$t('login.account')"
                  name="login"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="email"
                  :rules="rules.email"
                ></v-text-field>

                <v-text-field
                  id="password"
                  :label="$t('login.password')"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                  :rules="rules.password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login" :loading="loading">{{
                $t('login.login')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from 'axios';
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';

import { required } from '../validators/required';
import { email } from '../validators/email';

export default {
  mixins: [errors],

  data: () => ({
    email: '',
    password: '',
  }),

  computed: {
    ...mapGetters('security', {
      isAuthenticated: 'isAuthenticated',
      autologout: 'autologout',
      loading: 'loading',
    }),

    // Validation errors
    rules() {
      return {
        email: [required(this), email(this)],
        password: [required(this)],
      };
    },
  },

  methods: {
    login() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const { email, password } = this;

      this.$store
        .dispatch('security/login', { email, password })
        .then(() => {
          /**
           * Authentication
           */
          const token_type = localStorage.getItem('token_type');
          const access_token = localStorage.getItem('access_token');
          axios.defaults.headers.common['Authorization'] =
            token_type + access_token;

          // Redirect to orders
          this.redirectToScan();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    redirectToScan() {
      // Load all restaurants
      this.$store
        .dispatch('restaurant/getWaiterData')
        .then(response => {
          const restaurant = response.data;

          // Redirect to the orders
          this.$router.push({
            name: 'scanner',
            params: { table: restaurant.tables[0].uuid },
          });
        })
        .catch(error => {
          this.storeError(error);
        });
    },
  },
};
</script>

<style></style>
